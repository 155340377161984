import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import {
  faFacebook,
  faGooglePlus,
  faLinkedin,
  faPinterest,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { faEnvelope, faMapSigns, faMobile } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const apiUrl = process.env.REACT_APP_API_URL;

const FooterContainer = styled.div`
   background-image: ${(props) => props.$backgroundImg && `radial-gradient(ellipse at center, rgba(0, 0, 0, 0.57) 0%, rgba(0, 0, 0, 0.87) 100%), url(${apiUrl}/images/footerOptions/${props.$backgroundImg})`};
`;

function Footer({ logo, footerOptions }) {
  return (
    <>
      <FooterContainer $backgroundImg={footerOptions.backgroundImg} className="footer-box pad-top-70 pad-bottom-70">
        <Container>
          <Row>
            <div className="footer-in-main">
              <div className="footer-logo">
                <div className="text-center">
                  <img src={`${apiUrl}/images/presentationOptions/${logo}`} alt="" />
                </div>
              </div>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div className="footer-box-a">
                  <h3>À propos</h3>
                  <p>{footerOptions.description}</p>
                  <ul id="socialBox" className="socials-box footer-socials pull-left">
                    <li>
                      <a href={footerOptions.facebookLink} aria-label="Facebook">
                        <div className="social-circle-border"><FontAwesomeIcon icon={faFacebook} /></div>
                      </a>
                    </li>
                    <li>
                      <a href={footerOptions.twitterLink} aria-label="Twitter">
                        <div className="social-circle-border"><FontAwesomeIcon icon={faTwitter} /></div>
                      </a>
                    </li>
                    <li>
                      <a href={footerOptions.googleplusLink} aria-label="Google+">
                        <div className="social-circle-border"><FontAwesomeIcon icon={faGooglePlus} /></div>
                      </a>
                    </li>
                    <li>
                      <a href={footerOptions.pinterestLink} aria-label="Pinterest">
                        <div className="social-circle-border"><FontAwesomeIcon icon={faPinterest} /></div>
                      </a>
                    </li>
                    <li>
                      <a href={footerOptions.linkedinLink} aria-label="Linkedin">
                        <div className="social-circle-border"><FontAwesomeIcon icon={faLinkedin} /></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div className="footer-box-b">
                  <h3>Nouveaux Menus</h3>
                  <ul>
                    <li><Link spy smooth offset={-70} duration={500} to="menu" style={{ cursor: 'pointer' }}>Carri poulet</Link></li>
                    <li><Link spy smooth offset={-70} duration={500} to="menu" style={{ cursor: 'pointer' }}>Rougail de citrons</Link></li>
                    <li><Link spy smooth offset={-70} duration={500} to="menu" style={{ cursor: 'pointer' }}>Choka</Link></li>
                    <li><Link spy smooth offset={-70} duration={500} to="menu" style={{ cursor: 'pointer' }}>Boucané</Link></li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div className="footer-box-c">
                  <h3>Nous contacter</h3>
                  <p>
                    <FontAwesomeIcon icon={faMapSigns} size="lg" className="address" />
                    <span>{footerOptions.address}</span>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faMobile} />
                    <span>{footerOptions.phoneNumber}</span>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span><a href={`mailto:${footerOptions.mail}`}>{footerOptions.mail}</a></span>
                  </p>
                </div>
              </Col>
              <Col lg={3} md={3} sm={6} xs={12}>
                <div className="footer-box-d">
                  <h3>Horaires d&apos;ouverture</h3>
                  <ul>
                    <li>
                      <p>{footerOptions.firstOpeningDays}</p>
                      <span>{footerOptions.firstOpeningHours}</span>
                    </li>
                    <li>
                      <p>{footerOptions.secondaryOpeningDays}</p>
                      <span>{footerOptions.secondaryOpeningHours}</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </FooterContainer>

      <div id="copyright" className="copyright-main mt-0">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h6 className="copy-title">
                {footerOptions.copyright}
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
  footerOptions: PropTypes.shape({
    backgroundImg: PropTypes.string,
    description: PropTypes.string,
    facebookLink: PropTypes.string,
    twitterLink: PropTypes.string,
    googleplusLink: PropTypes.string,
    pinterestLink: PropTypes.string,
    linkedinLink: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstOpeningDays: PropTypes.string,
    firstOpeningHours: PropTypes.string,
    secondaryOpeningDays: PropTypes.string,
    secondaryOpeningHours: PropTypes.string,
    mail: PropTypes.string,
    copyright: PropTypes.string,
  }).isRequired,
};

export default Footer;
