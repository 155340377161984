import React, { useEffect, useState } from 'react';
import GenericTable from '../../Generic/GenericTable';
import { useApi } from '../../../../utils/hooks/admin';

function UserTable() {
  // Je récupère la taille de l'écran
  const { windowSize } = useApi();

  // State permettant de définir les informations des Users à afficher
  const [displayedColumns, setDisplayedColumns] = useState({});

  const defaultColumns = {
    username: true,
    roles: true,
    phoneNumber: false,
    email: false,
    hireDate: true,
    endDate: false,
    employmentStatus: false,
    socialSecurityNumber: false,
    comments: false,
  };

  const mobileColumns = {
    username: false,
    roles: false,
    phoneNumber: false,
    email: false,
    hireDate: false,
    endDate: false,
    employmentStatus: false,
    socialSecurityNumber: false,
    comments: false,
  };

  // Je change les colonnes à afficher en fct de la taille de l'écran
  useEffect(() => {
    if (windowSize.width <= 768) {
      setDisplayedColumns(mobileColumns);
    } else {
      setDisplayedColumns(defaultColumns);
    }
  }, [windowSize]);

  return (
    <GenericTable
      name="users"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
      associatedName="roles"
    />
  );
}

export default UserTable;
