import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import SpinnerWrapper from '../../SpinnerWrapper';
import { useApi } from '../../../../utils/hooks/admin';
import Searcher from '../../Searcher';
import {
  ingredientCategoryChoice,
  dishCategoryChoice,
  getEntityProperty,
  getModalTitle,
} from '../../../../utils/helpers/FormPart/DishFormPart';

function DishFormPart({
  isAddModal, // Booléen permettant de déterminer le mode (ajout ou édition)
  associatedEntitiesIds, // Tableau d'objets Ingredients {id, quantityNeeded} du plat
  setAssociatedEntitiesIds, // Modifie les ingrédients du plat
  allAssociatedEntities, // Tableau de tous les objets Ingredient
  selectedEntryColumns, // Objet contenant les informations du plat
  setSelectedEntryColumns, // Modifie les informations du plat
  isLoading,
  handleImageChange,
  handleSubmit,
  handleClose,
}) {
  const { errors, authPermissions } = useApi();

  // State contenant l'objet représentant l'ingrédient trouvé via l'input de recherche
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  // State contenant la quantité à ajouter pour l'ingrédient trouvé
  const [quantity, setQuantity] = useState(0);

  // State contenant le terme de la recherche de l'input de recherche
  const [searchTerm, setSearchTerm] = useState('');

  // Détermine en fonction des permissions si mes inputs doivent être disabled ou non
  const isInputDisabled = !authPermissions.includes('Mise à jour des plats');

  // Permet d'empêcher la validation du formulaire si aucun ingrédient n'a été spécifié
  const isAssociatedEntitiesEmpty = associatedEntitiesIds.length === 0;

  // Aide à définir le titre de la modale
  const modalTitle = getModalTitle(isAddModal, isInputDisabled);

  // Méthode permettant de modifier le state quantity (float uniquement acceptés)
  const handleQuantityChange = (event) => {
    // Si la value n'est pas un number je garde l'ancienne valeur du state sinon la nouvelle
    setQuantity(Number.isNaN(
      parseFloat(event.target.value, 10),
    )
      ? quantity
      : parseFloat(event.target.value, 10));
  };

  // Méthode ajoutant un ingrédient au plat ou augmentant sa quantité s'il existe déjà
  const handleAddIngredient = (ingredient) => {
    const existingIngredientIndex = associatedEntitiesIds.findIndex(
      (item) => item.id === ingredient.id,
    );
    // Si l'ingrédient existait déjà pour le plat
    if (existingIngredientIndex !== -1) {
      const updatedSelectedIngredients = [...associatedEntitiesIds];
      // J'ajoute la quantité
      updatedSelectedIngredients[existingIngredientIndex].quantityNeeded += quantity;
      setAssociatedEntitiesIds(updatedSelectedIngredients);
    } else {
      // Sinon j'ajoute l'ingrédient et sa quantité aux ingrédients
      setAssociatedEntitiesIds([
        ...associatedEntitiesIds,
        {
          id: ingredient.id,
          quantityNeeded: quantity,
        }]);
    }

    // Je reset mes states de recherche et de quantité
    setSelectedIngredient(null);
    setSearchTerm('');
    setQuantity(0);
  };

  const handleAddRemovableIngredient = (event, ingredientId) => {
    const { checked } = event.target;
    const existingIngredientIndex = associatedEntitiesIds.findIndex(
      (item) => item.id === ingredientId,
    );

    const updatedSelectedIngredients = [...associatedEntitiesIds];

    updatedSelectedIngredients[existingIngredientIndex].isRemovable = checked;
    setAssociatedEntitiesIds(updatedSelectedIngredients);
  };

  // Méthode permettant de retirer un ingrédient au plat
  const handleRemoveIngredient = (ingredientId) => {
    const updatedSelectedIngredients = associatedEntitiesIds.filter(
      (ingredient) => ingredient.id !== ingredientId,
    );
    setAssociatedEntitiesIds(updatedSelectedIngredients);
  };

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {modalTitle}
            de plat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>
                Nom
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSelectedEntryColumns(
                  { ...selectedEntryColumns, title: e.target.value },
                )}
                value={selectedEntryColumns.title}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('duplicateTitle')
              && <p className="text-primary">Ce plat est déjà enregistré.</p>}
              {errors.includes('emptyTitle')
              && <p className="text-primary">Le nom du plat doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>
                Description
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Courte présentation du plat..."
                onChange={
                  (e) => setSelectedEntryColumns(
                    { ...selectedEntryColumns, description: e.target.value },
                  )
                }
                value={selectedEntryColumns.description}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyDescription') && (
              <p className="text-primary">
                La description du plat doit être spécifiée
              </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>
                Prix (en €)
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                onChange={(e) => {
                  const { value } = e.target;
                  const price = parseFloat(value);
                  setSelectedEntryColumns((prevEntryColumns) => ({
                    ...prevEntryColumns,
                    price: Number.isNaN(price) ? prevEntryColumns.price : price,
                  }));
                }}
                value={selectedEntryColumns.price}
                required
                disabled={isInputDisabled}
              />
              {errors.includes('emptyPrice')
              && <p className="text-primary">Le prix doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="category">
              <Form.Label>
                Catégorie
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Select
                name="category"
                onChange={(event) => {
                  const { value } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    category: value,
                  }));
                }}
                value={selectedEntryColumns.category}
                required
                disabled={isInputDisabled}
              >
                <option value="" disabled>Sélectionner une catégorie</option>
                {dishCategoryChoice.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </Form.Select>
              {errors.includes('emptyCategory')
              && <p className="text-primary">Le plat doit appartenir à une catégorie</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Vendre ce plat ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={(event) => {
                  const { checked } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    isSaled: checked,
                  }));
                }}
                checked={selectedEntryColumns.isSaled}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Proposer au client de choisir la cuisson ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={(event) => {
                  const { checked } = event.target;
                  setSelectedEntryColumns((prevState) => ({
                    ...prevState,
                    cookingChoice: checked,
                  }));
                }}
                checked={selectedEntryColumns.cookingChoice}
              />
            </Form.Group>

            <Searcher
              name="dishesPart"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allEntities={allAssociatedEntities}
              setPartEntities={setSelectedIngredient}
              disabled={isInputDisabled}
            />

            {selectedIngredient && (
              <Form.Group className="mb-3">
                <Form.Label className="d-block">
                  Quantité en
                  {` (${selectedIngredient.unit}) `}
                  :
                </Form.Label>
                <Form.Control
                  className="d-inline w-20 me-4"
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                  min={1}
                />
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleAddIngredient(selectedIngredient)}
                >
                  Ajouter
                </Button>
              </Form.Group>
            )}
            <div>
              {associatedEntitiesIds.length > 0
              && <p className="text-decoration-underline fw-bolder">Ingrédients du plat :</p>}
              {ingredientCategoryChoice
                .filter(
                  (ingredientCategory) => associatedEntitiesIds.some(
                    (ingredient) => getEntityProperty(
                      ingredient.id,
                      'category',
                      allAssociatedEntities,
                    ) === ingredientCategory,
                  ),
                )
                .map((ingredientCategory, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <p className="fw-bolder">{ingredientCategory}</p>
                    <ul>
                      {associatedEntitiesIds
                        .filter(
                          (ingredient) => getEntityProperty(
                            ingredient.id,
                            'category',
                            allAssociatedEntities,
                          ) === ingredientCategory,
                        )
                        .map((ingredient) => (
                          <li key={ingredient.id} className="mb-2">
                            {getEntityProperty(ingredient.id, 'title', allAssociatedEntities)}
                            {' : '}
                            {ingredient.quantityNeeded}
                            {' '}
                            {getEntityProperty(ingredient.id, 'unit', allAssociatedEntities)}
                            {!isInputDisabled
                            && (
                            <Button
                              className="ms-4"
                              variant="primary"
                              size="sm"
                              onClick={() => handleRemoveIngredient(ingredient.id)}
                            >
                              Retirer
                            </Button>
                            )}
                            <Form.Group className="mb-3" controlId="isRemovable">
                              <Form.Label style={{ marginTop: '16px' }}>
                                Retirable par le client ?
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                name="isRemovable"
                                style={{ display: 'inline-block', marginLeft: '20px' }}
                                onChange={
                                  (event) => handleAddRemovableIngredient(event, ingredient.id)
                                }
                                checked={
                                  associatedEntitiesIds.find(
                                    (item) => item.id === ingredient.id,
                                  )?.isRemovable || false
                                }
                              />
                            </Form.Group>
                          </li>
                        ))}
                    </ul>
                  </React.Fragment>
                ))}
            </div>

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image du plat (440 * 560)</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                disabled={isInputDisabled}
              />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              {!isInputDisabled ? (
                <>
                  <Button
                    variant="success"
                    size="sm"
                    className="me-4"
                    type="submit"
                    disabled={isAssociatedEntitiesEmpty}
                  >
                    Valider
                  </Button>

                  <Button variant="primary" size="sm" onClick={handleClose}>
                    Annuler
                  </Button>
                </>
              ) : (
                <Button variant="primary" size="sm" onClick={handleClose}>
                  Retour
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

DishFormPart.propTypes = {
  isAddModal: PropTypes.bool.isRequired,
  associatedEntitiesIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantityNeeded: PropTypes.number.isRequired,
    }),
  ),
  setAssociatedEntitiesIds: PropTypes.func.isRequired,
  allAssociatedEntities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  selectedEntryColumns: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    category: PropTypes.string,
    isSaled: PropTypes.bool,
    cookingChoice: PropTypes.bool,
  }),
  setSelectedEntryColumns: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

DishFormPart.defaultProps = {
  associatedEntitiesIds: null,
  selectedEntryColumns: null,
};

export default DishFormPart;
