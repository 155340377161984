import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import {
  parseISO,
  format,
  parse,
  startOfWeek,
  getDay,
} from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../utils/css/custom-calendar.css';
import fr from 'date-fns/locale/fr';
import { Button, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useApi } from '../../../utils/hooks/admin';
import SpinnerWrapper from '../SpinnerWrapper';
import GenericAddAndEditModal from '../Generic/GenericAddAndEditModal';
import PlanningDeleteModal from '../PlanningDeleteModal';

const PlanningButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
`;

const locales = {
  fr,
};

const localizer = dateFnsLocalizer({
  format: (date, formatStr, options) => format(date, formatStr, { ...options, locale: fr }),
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: fr }),
  getDay,
  locales,
});

function Planning() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    authToken,
    authId,
    authUser,
    authPermissions,
    fetchData,
  } = useApi();

  // State contenant les heures de travail des employés
  const [hours, setHours] = useState([]);

  // State permettant de gérer le spinner de chargement
  const [isLoading, setIsLoading] = useState(true);

  // State permettant de définir la modale à ouvrir
  const [modalType, setModalType] = useState('');

  // State permettant de relancer la recherche d'horaires de travail
  const [reloadData, setReloadData] = useState(false);

  const handleAddHours = () => {
    setModalType('add');
  };

  const handleRemoveHours = () => {
    setModalType('remove');
  };

  useEffect(() => {
    const fetchHours = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      let url;
      let userHours = [];

      if (authPermissions.includes('Accès au planning des employés')) {
        url = `${apiUrl}/api/usersPlanning`;

        const { data } = await fetchData(url, options);

        // Parcours chaque utilisateur et ajoute leurs horaires de travail
        data['hydra:member'].forEach((fetchedUser) => {
          fetchedUser.workingHours.forEach((workingHourData) => {
            userHours.push({
              id: workingHourData.id,
              title: `${fetchedUser.username} - ${workingHourData.activity}`,
              start: parseISO(workingHourData.startTime),
              end: parseISO(workingHourData.endTime),
            });
          });
        });
      } else {
        url = `${apiUrl}/api/users/planning/${authId}`;

        const { data: workingHoursData } = await fetchData(url, options);

        // Ajoute les horaires pour l'utilisateur connecté
        userHours = workingHoursData.workingHours.map((workingHourData) => ({
          title: workingHourData.activity,
          start: parseISO(workingHourData.startTime),
          end: parseISO(workingHourData.endTime),
        }));
      }

      setHours(userHours);
      setIsLoading(false);
    };

    fetchHours();
  }, [reloadData]);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Container fluid className="pt-4 px-4">
        <div className="bg-secondary rounded p-4">
          <h6 style={{ marginBottom: '33.6px' }}>
            {authPermissions.includes('Accès au planning des employés')
              ? 'Planning des employés'
              : `Planning de ${authUser}`}
          </h6>
          <Calendar
            localizer={localizer}
            events={hours}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1000 }}
            culture="fr" // Définit la culture en français
            messages={{
              date: 'Date',
              time: 'Heure',
              event: 'Événement',
              allDay: 'Journée entière',
              week: 'Semaine',
              work_week: 'Semaine de travail',
              day: 'Jour',
              month: 'Mois',
              previous: 'Précédent',
              next: 'Suivant',
              yesterday: 'Hier',
              tomorrow: 'Demain',
              today: "Aujourd'hui",
              agenda: 'Agenda',
              noEventsInRange: 'Aucun événement dans cette période.',
              showMore: (total) => `+ ${total} plus`,
            }}
          />
          {authPermissions.includes('Accès au planning des employés') && (
            <PlanningButtons>
              <Button variant="success" onClick={handleAddHours}>Attribuer des horaires</Button>
              <Button onClick={handleRemoveHours}>Retirer des horaires</Button>
            </PlanningButtons>
          )}
        </div>
        {modalType === 'add'
        && (
          <GenericAddAndEditModal
            name="planning"
            handleClose={() => setModalType('')}
            handleSuccess={() => {
              setReloadData(!reloadData);
            }}
            selectedEntry={null}
          />
        )}
        {modalType === 'remove'
        && (
          <PlanningDeleteModal
            handleClose={() => setModalType('')}
            handleSuccess={() => {
              setReloadData(!reloadData);
            }}
            hours={hours}
          />
        )}
      </Container>
    </>
  );
}

export default Planning;
