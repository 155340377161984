/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useApi } from '../../../../utils/hooks/admin';
import { carouselIndexSort, finalProductDisplayedBuilder } from '../../../../utils/helpers/FormPart/SpecialOptionsFormPart';

const SpecialSection = styled.div`
  position: relative;
  border: 2px solid white;
  padding: 35px 15px 35px 15px;
  margin-bottom: 50px;
`;

const SpecialTitle = styled.h6`
  position: absolute;
  top: -9.6px;
  background-color: var(--secondary);
  padding: 0px 5px 0px 5px;
`;

function SpecialOptionsFormPart({
  handleSubmit,
  handleImageChange,
  entries,
  setEntries,
  setIsLoading,
  productsDisplayed,
  setProductsDisplayed,
  initialProductsDisplayed,
  setInitialProductsDisplayed,
  setFinalDishesAdded,
  setFinalDishesRemoved,
  setFinalMenusAdded,
  setFinalMenusRemoved,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { fetchData, authToken } = useApi();
  const [dishes, setDishes] = useState([]);
  const [initialDishesDisplayed, setInitialDishesDisplayed] = useState([]);
  const [menus, setMenus] = useState([]);
  const [initialMenusDisplayed, setInitialMenusDisplayed] = useState([]);

  const selectOptions = [
    {
      label: 'Plats',
      options: dishes?.map((dish) => ({
        value: dish.id,
        label: dish.title,
      })),
    },
    {
      label: 'Menus',
      options: menus?.map((menu) => ({
        value: menu.id,
        label: menu.title,
      })),
    },
  ];

  const handleSelectChange = (value, index) => {
    setProductsDisplayed((prevState) => {
      const newState = [...prevState];
      newState[index] = { id: parseInt(value, 10), carouselIndex: index };
      return newState;
    });
  };

  useEffect(() => {
    // Méthode permettant la récupération de la liste des entités
    const fechDataAsync = async () => {
      setIsLoading(true);
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      // Requête l'API récupérant la liste des entités
      const { data: dishesData } = await fetchData(`${apiUrl}/api/dishes?isDeleted=false`, options);

      // Je récupère toutes les informations pertinentes concernant mes entrées
      setDishes(dishesData['hydra:member']
        .map((entry) => ({
          ...entry,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)));

      // Requête l'API récupérant la liste des entités
      const { data: menusData } = await fetchData(`${apiUrl}/api/menus?isDeleted=false`, options);

      // Je récupère toutes les informations pertinentes concernant mes entrées
      setMenus(menusData['hydra:member']
        .map((entry) => ({
          ...entry,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)));
    };

    fechDataAsync();
  }, []);

  useEffect(() => {
    if (dishes.length > 0 && menus.length > 0) {
      setInitialDishesDisplayed(
        dishes.filter((entry) => entry.carouselIndex !== null
        && entry.carouselIndex !== undefined)
          .map((entry) => ({ id: entry.id, carouselIndex: entry.carouselIndex })),
      );

      setInitialMenusDisplayed(
        menus.filter((entry) => entry.carouselIndex !== null
        && entry.carouselIndex !== undefined)
          .map((entry) => ({ id: entry.id, carouselIndex: entry.carouselIndex })),
      );
    }
  }, [dishes, menus]);

  useEffect(() => {
    if (initialDishesDisplayed.length > 0 || initialMenusDisplayed.length > 0) {
      const allProductsDisplayed = [...initialDishesDisplayed, ...initialMenusDisplayed];
      carouselIndexSort(allProductsDisplayed);

      setProductsDisplayed(allProductsDisplayed);
      setInitialProductsDisplayed(allProductsDisplayed);

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    }
  }, [initialDishesDisplayed, initialMenusDisplayed]);

  useEffect(() => {
    if (productsDisplayed.length > 0) {
      const res = finalProductDisplayedBuilder(
        initialProductsDisplayed,
        productsDisplayed,
        dishes,
        menus,
      );

      setFinalDishesAdded(res.finalDishesAdded);
      setFinalDishesRemoved(res.finalDishesRemoved);
      setFinalMenusAdded(res.finalMenusAdded);
      setFinalMenusRemoved(res.finalMenusRemoved);
    }
  }, [productsDisplayed]);

  return (
    <SpecialSection>
      <SpecialTitle>Section Spécial</SpecialTitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="flexSwitchCheckChecked">
          <Form.Check
            type="switch"
            id="flexSwitchCheckChecked"
            label="Afficher cette section ?"
            checked={entries.isEnabled}
            onChange={(e) => setEntries({ ...entries, isEnabled: e.target.checked })}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="specialTitle">Titre</FormLabel>
          <FormControl
            type="text"
            id="specialTitle"
            onChange={(e) => setEntries({ ...entries, title: e.target.value })}
            value={entries.title}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="specialDescription">Description</FormLabel>
          <FormControl
            as="textarea"
            rows={5}
            id="specialDescription"
            value={entries.description}
            onChange={(e) => setEntries({ ...entries, description: e.target.value })}
          />
        </FormGroup>

        {productsDisplayed.length > 0 && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Premier plat ou menu du carousel :</Form.Label>
              <Form.Select
                className="w-auto"
                size="sm"
                aria-label=".form-select-lg example"
                onChange={(e) => handleSelectChange(e.target.value, 0)}
                value={productsDisplayed[0].id}
              >
                {selectOptions.map((category, index) => (
                  <optgroup label={category.label} key={index}>
                    {category.options.map((option, index) => {
                      // On vérifie si l'option est déjà sélectionnée sur un autre select
                      const isOptionDisabled = productsDisplayed.some(
                        (productDisplayed) => productDisplayed.id === option.value,
                      );
                      return (
                        <option
                          value={option.value}
                          key={index}
                          disabled={isOptionDisabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Deuxième plat ou menu du carousel :</Form.Label>
              <Form.Select
                className="w-auto"
                size="sm"
                aria-label=".form-select-lg example"
                onChange={(e) => handleSelectChange(e.target.value, 1)}
                value={productsDisplayed[1].id}
              >
                {selectOptions.map((category, index) => (
                  <optgroup label={category.label} key={index}>
                    {category.options.map((option, index) => {
                      const isOptionDisabled = productsDisplayed.some(
                        (productDisplayed) => productDisplayed.id === option.value,
                      );
                      return (
                        <option
                          value={option.value}
                          key={index}
                          disabled={isOptionDisabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Troisième plat ou menu du carousel :</Form.Label>
              <Form.Select
                className="w-auto"
                size="sm"
                aria-label=".form-select-lg example"
                onChange={(e) => handleSelectChange(e.target.value, 2)}
                value={productsDisplayed[2].id}
              >
                {selectOptions.map((category, index) => (
                  <optgroup label={category.label} key={index}>
                    {category.options.map((option, index) => {
                      const isOptionDisabled = productsDisplayed.some(
                        (productDisplayed) => productDisplayed.id === option.value,
                      );
                      return (
                        <option
                          value={option.value}
                          key={index}
                          disabled={isOptionDisabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quatrième plat ou menu du carousel :</Form.Label>
              <Form.Select
                className="w-auto"
                size="sm"
                aria-label=".form-select-lg example"
                onChange={(e) => handleSelectChange(e.target.value, 3)}
                value={productsDisplayed[3].id}
              >
                {selectOptions.map((category, index) => (
                  <optgroup label={category.label} key={index}>
                    {category.options.map((option, index) => {
                      const isOptionDisabled = productsDisplayed.some(
                        (productDisplayed) => productDisplayed.id === option.value,
                      );
                      return (
                        <option
                          value={option.value}
                          key={index}
                          disabled={isOptionDisabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Cinquième plat ou menu du carousel :</Form.Label>
              <Form.Select
                className="w-auto"
                size="sm"
                aria-label=".form-select-lg example"
                onChange={(e) => handleSelectChange(e.target.value, 4)}
                value={productsDisplayed[4].id}
              >
                {selectOptions.map((category, index) => (
                  <optgroup label={category.label} key={index}>
                    {category.options.map((option, index) => {
                      const isOptionDisabled = productsDisplayed.some(
                        (productDisplayed) => productDisplayed.id === option.value,
                      );
                      return (
                        <option
                          value={option.value}
                          key={index}
                          disabled={isOptionDisabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Group>
          </>
        )}

        <FormGroup className="mb-3">
          <FormLabel htmlFor="specialBackgroundImg">Image du background</FormLabel>
          <Form.Control
            type="file"
            id="specialBackgroundImg"
            className="bg-dark"
            onChange={(e) => handleImageChange(e, 'backgroundImg')}
          />
        </FormGroup>
        <Button type="submit" variant="success">Valider</Button>
      </Form>
    </SpecialSection>
  );
}

SpecialOptionsFormPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  entries: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    isEnabled: PropTypes.bool,
  }).isRequired,
  setEntries: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  productsDisplayed: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  setProductsDisplayed: PropTypes.func.isRequired,
  initialProductsDisplayed: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  setInitialProductsDisplayed: PropTypes.func.isRequired,
  setFinalDishesAdded: PropTypes.func.isRequired,
  setFinalDishesRemoved: PropTypes.func.isRequired,
  setFinalMenusAdded: PropTypes.func.isRequired,
  setFinalMenusRemoved: PropTypes.func.isRequired,
};

SpecialOptionsFormPart.defaultProps = {
  initialProductsDisplayed: null,
  productsDisplayed: null,
};

export default SpecialOptionsFormPart;
