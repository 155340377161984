/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';

function Team({ teamOptions }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <Container>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
            <h2 className="block-title text-center">{teamOptions.title}</h2>
            <p className="title-caption text-center">{teamOptions.description}</p>
          </div>
          <div className="team-box">
            <Row>
              {teamOptions.teamMembers.map((teamMember) => (
                <Col key={teamMember.id} md={4} sm={6}>
                  <div className="sf-team">
                    <div className="thumb">
                      <a href="#"><img src={`${apiUrl}/images/teamMembers/${teamMember.imageName}`} alt="" /></a>
                    </div>
                    <div className="text-col">
                      <h3>{teamMember.name}</h3>
                      <p>{teamMember.presentation}</p>
                      <ul className="team-social">
                        <li><a href={teamMember.facebookLink} aria-label={`Page Facebook de ${teamMember.name}`}><i className="fa fa-facebook" aria-hidden="true" /></a></li>
                        <li><a href={teamMember.twitterLink} aria-label={`Page Twitter de ${teamMember.name}`}><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                        <li><a href={teamMember.linkedinLink} aria-label={`Page Linkedin de ${teamMember.name}`}><i className="fa fa-linkedin" aria-hidden="true" /></a></li>
                      </ul>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
}

Team.propTypes = {
  teamOptions: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    teamMembers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imageName: PropTypes.string,
        name: PropTypes.string,
        presentation: PropTypes.string,
        facebookLink: PropTypes.string,
        twitterLink: PropTypes.string,
        linkedinLink: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default Team;
