import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const CustomModal = styled.div`
  pointer-events: ${(props) => (props.$showModal === 'true' ? 'auto' : 'none')};
  position: fixed;
  // Les 2 règles de position centrent parfaitement l'élément
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: ${(props) => (props.$showModal === 'true' ? 'scroll' : 'none')};
  // NE PAS UTILISER MIX OU MAX SINON L'IMAGE SERA DIMENSIONNEÉ N'IMPORTE COMMENT
  width: 60%;
  // Heigh est obligatoire pour faire fonctionner
  height: 90%;
  z-index: 1001;
  padding: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  transform-origin: center;
  // Attention à ne pas mêler transition de visiblité avec display
  transform: ${(props) => (props.$showModal === 'true' ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.5s ease;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
`;

const ImageFooter = styled.h4`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 300;
  padding: 10px 25px;
  text-align: left;
  color: white;
  background-color: rgba(0,0,0,0.5);
  border-radius: 0px 0px 6px 6px;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: black;
  border-radius: 50%;
  border: 3px solid white;
  height: 30px;
  width: 30px;
  color: white;
  font-family: none;
  z-index: 9999;
  cursor: pointer;
`;

const Overlay = styled.div`
  pointer-events: ${(props) => (props.$showModal === 'true' ? 'auto' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 1000;
  opacity: ${(props) => (props.$showModal === 'true' ? '1' : '0')};
  transition: opacity 0.5s ease;
`;

function GalleryModal({
  showModal,
  handleClose,
  imageClicked,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      <CustomModal $showModal={showModal ? 'true' : 'false'}>
        <ImageContainer $showModal={showModal ? 'true' : 'false'}>
          <Image src={`${apiUrl}/images/galleryImages/${imageClicked.imageName}`} alt="" />
          <ImageFooter>{imageClicked.description}</ImageFooter>
          <CloseButton onClick={handleClose}>x</CloseButton>
        </ImageContainer>
      </CustomModal>
      <Overlay $showModal={showModal ? 'true' : 'false'} onClick={handleClose} />
    </>
  );
}

GalleryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imageClicked: PropTypes.shape({
    imageName: PropTypes.string,
    description: PropTypes.string,
  }),
};

GalleryModal.defaultProps = {
  imageClicked: {
    src: null,
    description: null,
  },
};

export default GalleryModal;
