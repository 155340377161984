/* eslint-disable no-unused-vars */
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, useState } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../utils/hooks/admin';
import PersonalInfosFormPart from '../../FormPart/PersonalInfosFormPart';

function NavBar() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    setErrors,
    authUser,
    authImg,
    resetUserAuth,
    resetCache,
  } = useApi();

  // State permettant de gérer l'état de la liste déroulante
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // State permettant de gérer l'état de la modale de profil
  const [isModal, setIsModal] = useState(false);

  // Permet la redirection
  const navigate = useNavigate();

  // Méthode déclenchée au clic sur le bouton d'expansion et qui ajoute ou retire une classe
  const handleSidebarToggle = () => {
    document.querySelectorAll('.sidebar, .content').forEach((el) => {
      el.classList.toggle('open');
    });
  };

  // Méthode déclenchée au clic sur la liste déroulante et inversant son état (ouvert / fermé)
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Méthode déclenchée au clic sur l'élément relatif à la déconnexion
  const handleDisconnect = () => {
    // Vide les messages d'erreur qui pourraient être encore stockés
    setErrors([]);

    // Vide entièrement le cache
    resetCache();

    // Vide toutes les informations du user connecté
    resetUserAuth();

    // Vide le LocalStorage supprimant ainsi les informations sur le User connecté
    localStorage.clear();

    // Redirige vers la page Login
    navigate('/login');
  };

  const handleProfileModal = async () => {
    setIsModal((prevState) => !prevState);
  };

  return (
    <>
      {isModal && (
        <PersonalInfosFormPart handleProfileModal={handleProfileModal} />
      )}
      <Navbar expand="lg" bg="secondary" variant="dark" className="navbar sticky-top px-4 py-0">
        <Nav.Link href="#" className="sidebar-toggler flex-shrink-0" onClick={handleSidebarToggle}>
          <FontAwesomeIcon icon={faBars} />
        </Nav.Link>
        <Nav className="navbar-nav align-items-center ms-auto">
          <Dropdown className="nav-item dropdown" onClick={handleDropdownClick} show={isDropdownOpen}>
            <Dropdown.Toggle className="nav-link dropdown-toggle" variant="transparent" id="dropdown-profile">
              {authImg && (
                <img className="rounded-circle me-lg-2" src={`${apiUrl}/images/users/${authImg}`} alt="" style={{ width: '40px', height: '40px' }} />
              )}
              <span className="d-none d-lg-inline-flex">{authUser}</span>
              <FontAwesomeIcon
                icon={faAngleDown}
                size="sm"
                className={`navIcon-after-dropdown ${isDropdownOpen ? 'rotate180' : ''}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0 ${isDropdownOpen ? 'show' : ''}`}>
              <Dropdown.Item onClick={handleProfileModal}>Mon Profil</Dropdown.Item>
              <Dropdown.Item onClick={handleDisconnect}>Déconnexion</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>
    </>
  );
}

export default NavBar;
