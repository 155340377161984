import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useApi, useModalManagement } from '../../../../utils/hooks/admin';
import IngredientFormPart from '../../FormPart/IngredientFormPart';
import { getEntryColumns } from '../../../../utils/helpers/Generic/GenericAssociatedAddAndEditModal';
import OrderFormPart from '../../FormPart/OrderFormPart';
import getBodyData from '../../../../utils/helpers/Generic/GenericAddAndEditModal';
import PlanningFormPart from '../../FormPart/PlanningFormPart';

function GenericAddAndEditModal({
  name, // Nom de l'entité associée à la modale
  handleClose,
  handleSuccess,
  selectedEntry, // Objet contenant les informations de l'entité
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { fetchData, authToken } = useApi();

  const { handleSuccessInModal } = useModalManagement();

  // Objet ne contenant que certaines propriétés de celui passé en prop
  const entryColumns = getEntryColumns(name, selectedEntry);

  // State contenant les informations de mon entité
  const [selectedEntryColumns, setSelectedEntryColumns] = useState(entryColumns);

  // Contient les informations à envoyer concernant l'image donnée par l'utilisateur
  const [imageInfos, setImageInfos] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Méthode permettant de mettre à jour le state avec les informations du fichier image donné
  const handleImageChange = (e) => {
    // On récupère le fichier passé
    const file = e.target.files[0];

    // On met à jour notre state avec le contenu du fichier et son nom
    setImageInfos({
      imageFile: file,
      imageName: file.name,
    });
  };

  // Requête l'API à la soumission du formulaire
  const handleSubmit = async (e) => {
    // Mes requêtes vont s'effectuer, j'affiche mon loading
    setIsLoading(true);

    if (name !== 'planning') {
      // On retire le comportement par défaut du formulaire
      e.preventDefault();
    }

    // Contiendra le fichier. FormData est obligatoire pour pouvoir le transmettre à l'API
    const ImageformData = new FormData();

    // Si une image a été spécifiée
    if (imageInfos !== null) {
      // On met à jour notre entité en lui ajoutant les information de l'image
      ImageformData.append('image', imageInfos.imageFile);
      ImageformData.append('imageName', imageInfos.imageName);
    }

    // Contient une partie du corps de la requête
    let bodyData;
    if (name === 'ingredients') {
      bodyData = getBodyData(name, selectedEntryColumns);
    } else {
      bodyData = selectedEntryColumns;
    }

    // Informations nécessaires pour la requête
    const entityOptions = {
      method: selectedEntry ? 'PATCH' : 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'content-type': selectedEntry ? 'application/merge-patch+json' : 'application/ld+json',
      },
      body: JSON.stringify(bodyData),
    };

    // Méthode requêtant l'API
    // eslint-disable-next-line no-unused-vars
    const submitEntity = async () => {
      /* ************ TRAITEMENT DE L'AJOUT / MODIF DE L'ENTITÉ  ************ */

      // Si un selectedEntry a été spécifié on utilise l'url pour le PUT sinon c'est celle du POST
      const entityUrl = selectedEntry
        ? `${apiUrl}/api/${name}/${selectedEntry.id}`
        : `${apiUrl}/api/${name}`;

      // On requête l'API pour traiter l'inscription ou la modification d'un User
      const { data: entityData, response } = await fetchData(entityUrl, entityOptions);

      if (!entityData) {
        setIsLoading(false);
        return;
      }

      /* ************ FIN DU TRAITEMENT DE L'AJOUT / MODIF DE L'ENTITÉ ************ */

      /* --------------------------------------------------- */

      /* ************ TRAITEMENT DE L'IMAGE ************ */

      // Si une image a été spécifiée
      if (imageInfos !== null) {
        const imagePostOptions = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: ImageformData,
        };

        // On construit l'url de l'image associée à l'entité à partir de l'id de cette dernière
        const imagePostUrl = selectedEntry ? `${apiUrl}/api/${name}/${selectedEntry.id}` : `${apiUrl}/api/${name}/${entityData.id}`;

        // On requête l'API pour inscrire la nouvelle image
        await fetchData(imagePostUrl, imagePostOptions);
      }

      /* ************ FIN DU TRAITEMENT DE L'IMAGE ************ */

      // Méthode permettant de gérer le comportement de la modale en cas de succès de la requête
      handleSuccessInModal(response, handleClose, handleSuccess, setIsLoading);
    };

    submitEntity();
  };

  return (
    <>
      {name === 'ingredients' && (
        <IngredientFormPart
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isLoading={isLoading}
          handleImageChange={handleImageChange}
        />
      )}
      {name === 'tables' && (
        <OrderFormPart
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      )}
      {name === 'planning' && (
        <PlanningFormPart
          selectedEntryColumns={selectedEntryColumns}
          setSelectedEntryColumns={setSelectedEntryColumns}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

GenericAddAndEditModal.propTypes = {
  name: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  selectedEntry: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    quantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    unit: PropTypes.string,
    category: PropTypes.string,
    isAllergen: PropTypes.bool,
  }),
};

GenericAddAndEditModal.defaultProps = {
  selectedEntry: null,
};

export default GenericAddAndEditModal;
