import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useApi, useModalManagement } from '../../../../utils/hooks/admin';
import SpinnerWrapper from '../../SpinnerWrapper';
import getBodyContent from '../../../../utils/helpers/Generic/GenericDeleteModal';

function GenericDeleteModal({
  name, // Nom de l'entité associée à la modale
  selectedEntries, // Tableau de noms d'entités à supprimer
  setSelectedEntries, // Met à jour le tableau de nom d'entité
  handleClose,
  handleSuccess,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { errors, fetchData, authToken } = useApi();
  const { handleSuccessInModal } = useModalManagement();

  const [isLoading, setIsLoading] = useState(false);

  // Méthode permettant l'appel API
  const handleDelete = async () => {
    setIsLoading(true);

    // Informations nécessaires pour la requête
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getBodyContent(name, selectedEntries)),
    };

    /*
      Interroge l'API en demandant la suppression de toutes les
      entités du tableau identifiées par leur nom
    */
    const { response } = await fetchData(`${apiUrl}/api/${name}`, options);

    if (!response.ok) {
      setIsLoading(false);
      return;
    }
    // Si la requête a réussi, ferme la modale et "recharge" la page
    handleSuccessInModal(response, handleClose, handleSuccess, setIsLoading);

    // Vide le tableau des utilisateurs à supprimer
    setSelectedEntries([]);
  };

  // Permet de faire varier le comportement lors de la fermeture de la modale en fct de l'entité
  const handleModalClose = () => {
    if (name === 'orders') {
      setSelectedEntries([]);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Suppression
            {(() => {
              if (name === 'users') {
                return ' d\'employé';
              } if (name === 'ingredients') {
                return ' d\'ingrédient';
              } if (name === 'menus') {
                return ' de menus';
              } if (name === 'tables') {
                return ' de tables';
              } if (name === 'orders') {
                return ' de tickets';
              }
              return '';
            })()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            Voulez-vous supprimer
            {' '}
            {name === 'tables' ? 'la' : 'le'}
            {' '}
            ou les
            {(() => {
              if (name === 'users') {
                return ' employés ';
              } if (name === 'ingredients') {
                return ' ingrédients ';
              } if (name === 'menus') {
                return ' menus ';
              } if (name === 'tables') {
                return ' tables numéro ';
              } if (name === 'orders') {
                return ' tickets ';
              }
              return '';
            })()}
            ?
            {name === 'orders' && (
              <span>
                <br />
                (Les graphiques ne les prendront plus en compte)
              </span>
            )}
          </p>
          <p>
            {selectedEntries.map((username, index) => [
              <span key={username}>{username}</span>,
              index !== selectedEntries.length - 1 && <br key={`br-${username}`} />,
            ])}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center w-100">
            <Button variant="success" size="sm" className="me-4" onClick={handleDelete}>
              Valider
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setSelectedEntries([]);
                handleClose();
              }}
            >
              Annuler
            </Button>
          </div>
        </Modal.Footer>
        {errors && <p className="text-primary text-center">{errors}</p>}
      </Modal>
    </>
  );
}

GenericDeleteModal.propTypes = {
  name: PropTypes.string.isRequired,
  selectedEntries: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  setSelectedEntries: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,

};

export default GenericDeleteModal;
