import React, { useEffect, useState } from 'react';
import GenericTable from '../../Generic/GenericTable';
import { useApi } from '../../../../utils/hooks/admin';

function TableList() {
  // Je récupère la taille de l'écran
  const { windowSize } = useApi();

  // State permettant de définir les informations des Users à afficher
  const [displayedColumns, setDisplayedColumns] = useState({});

  const defaultColumns = {
    number: true,
    isOccupied: true,
    urlIdentifier: true,
  };

  const mobileColumns = {
    number: true,
    isOccupied: false,
    urlIdentifier: false,
  };

  // Je change les colonnes à afficher en fct de la taille de l'écran
  useEffect(() => {
    if (windowSize.width <= 768) {
      setDisplayedColumns(mobileColumns);
    } else {
      setDisplayedColumns(defaultColumns);
    }
  }, [windowSize]);

  return (
    <GenericTable
      name="tables"
      displayedColumns={displayedColumns}
      setDisplayedColumns={setDisplayedColumns}
    />
  );
}

export default TableList;
