import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { useApi } from '../../../../utils/hooks/admin';
import SpinnerWrapper from '../../SpinnerWrapper';
import { ingredientCategoryChoice } from '../../../../utils/helpers/FormPart/DishFormPart';
import unitChoice from '../../../../utils/helpers/FormPart/IngredientFormPart';
import getCurrentDate from '../../../../utils/helpers/FormPart/UserFormPart';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

function IngredientFormPart({
  selectedEntryColumns, // Objet contenant les informations de l'ingrédient en cours d'édition
  setSelectedEntryColumns, // Modifie les informations de l'ingrédient
  handleSubmit,
  handleClose,
  isLoading,
  handleImageChange,
}) {
  const { errors } = useApi();

  // Gère le mode entre Ajout et Redéfinition
  const [selectedOption, setSelectedOption] = useState('add');

  // Méthode permettant de mettre à jour les informations de l'ingrédient
  const handleInputChange = (e) => {
    const {
      name, value, type, checked,
    } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    // Si le nom de l'input correspond à une quantité
    if (name === 'quantity' || name === 'maxQuantity' || name === 'quantityIncreased') {
      // On s'assurera que la value soit bien un float
      const quantity = parseFloat(newValue);
      setSelectedEntryColumns((prevEntryColumns) => ({
        ...prevEntryColumns,
        [name]: Number.isNaN(quantity) ? prevEntryColumns[name] : quantity,
      }));
    } else if (name === 'increaseInterval') {
      const quantity = parseInt(newValue, 10);
      setSelectedEntryColumns((prevEntryColumns) => ({
        ...prevEntryColumns,
        [name]: Number.isNaN(quantity) ? prevEntryColumns[name] : quantity,
      }));
    } else {
      setSelectedEntryColumns((prevEntryColumns) => ({
        ...prevEntryColumns,
        [name]: newValue,
      }));
    }
  };

  // Permet de modifier le mode en fonction du bouton cliqué
  const handleButtonClick = (option) => {
    setSelectedOption(option);
    setSelectedEntryColumns((prevEntryColumns) => ({
      ...prevEntryColumns,
      isAdded: option === 'add',
    }));
  };

  // Permet de se mettre en mode ajout par défaut et sans clic sur le choix du mode
  useEffect(() => {
    setSelectedEntryColumns((prevEntryColumns) => ({
      ...prevEntryColumns,
      isAdded: true,
    }));
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Modal show onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {selectedEntryColumns.title !== '' ? 'Édition ' : 'Ajout '}
            d&apos;ingrédients
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="title">
              <Form.Label>
                Nom
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={handleInputChange}
                value={selectedEntryColumns.title}
                required
              />
              {errors.includes('duplicateTitle')
              && <p className="text-primary">Cet ingrédient est déjà répertorié.</p>}
              {errors.includes('emptyTitle')
              && <p className="text-primary">Le nom doit être spécifié.</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="maxQuantity">
              <Form.Label>
                Stock max
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                name="maxQuantity"
                onChange={handleInputChange}
                value={selectedEntryColumns.maxQuantity}
                required
              />
              {errors.includes('emptyMaxQuantity')
              && <p className="text-primary">La quantité maximale doit être spécifiée</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="quantity">
              <Form.Label>
                Stock actuel
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                step="any"
                max={selectedEntryColumns.maxQuantity}
                name="quantity"
                onChange={handleInputChange}
                value={selectedEntryColumns.quantity}
                required
              />
              {errors.includes('emptyQuantity')
              && <p className="text-primary">La quantité doit être spécifiée</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="unit">
              <Form.Label>
                Unité
                <span className="text-primary ml-2">*</span>
              </Form.Label>
              <Form.Select
                name="unit"
                onChange={handleInputChange}
                value={selectedEntryColumns.unit}
                required
              >
                <option value="" disabled>Sélectionner une unité de mesure</option>
                {Object.entries(unitChoice).map(([group, units]) => (
                  <optgroup label={group} key={group}>
                    {units.map((unit) => (
                      <option key={unit} value={unit}>{unit}</option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select>
              {errors.includes('emptyUnit')
              && <p className="text-primary">L&apos;unité de mesure doit être spécifiée</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="unit">
              <Form.Label>
                Catégorie
                <span className="text-primary ml-2">
                  *
                </span>
              </Form.Label>
              <Form.Select
                name="category"
                onChange={handleInputChange}
                value={selectedEntryColumns.category}
                required
              >
                <option value="" disabled>Sélectionner une catégorie</option>
                {ingredientCategoryChoice.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </Form.Select>
              {errors.includes('emptyCategory')
              && <p className="text-primary">La catégorie doit être spécifiée</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isAllergen">
              <Form.Label>
                Allergène ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="isAllergen"
                onChange={handleInputChange}
                checked={selectedEntryColumns.isAllergen}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="alert">
              <Form.Label>
                Déclencher alerte de stock ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="alert"
                onChange={handleInputChange}
                checked={selectedEntryColumns.alert}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="increaseMode">
              <Form.Label>
                Incrémentation automatique du stock ?
              </Form.Label>
              <Form.Check
                type="checkbox"
                name="increaseMode"
                onChange={handleInputChange}
                checked={selectedEntryColumns.increaseMode}
              />
            </Form.Group>

            {selectedEntryColumns.increaseMode && (
              <>
                <Form.Group className="mb-3" controlId="startingDay">
                  <Form.Label>
                    1er jour d&apos;incrémentation auto
                    <span className="text-primary ml-2">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    min={getCurrentDate()}
                    name="startingDay"
                    onChange={handleInputChange}
                    value={selectedEntryColumns.startingDay}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="increaseInterval">
                  <Form.Label>
                    Intervalle entre 2 incrémentations (en jours)
                    <span className="text-primary ml-2">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    step="any"
                    name="increaseInterval"
                    onChange={handleInputChange}
                    value={selectedEntryColumns.increaseInterval}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Ajouter ou Redéfinir la quantité ?
                  </Form.Label>
                  <ButtonGroup>
                    <Button
                      variant={selectedOption === 'add' ? 'primary' : 'secondary'}
                      onClick={() => handleButtonClick('add')}
                    >
                      Ajouter
                    </Button>
                    <Button
                      variant={selectedOption === 'redefine' ? 'primary' : 'secondary'}
                      onClick={() => handleButtonClick('redefine')}
                    >
                      Redéfinir
                    </Button>
                  </ButtonGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="quantityIncreased">
                  <Form.Label>
                    Ajouter ou redéfinir à (en
                    {` ${selectedEntryColumns.unit}`}
                    )
                    <span className="text-primary ml-2">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    step="any"
                    name="quantityIncreased"
                    onChange={handleInputChange}
                    value={selectedEntryColumns.quantityIncreased}
                    required
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-3" controlId="image">
              <Form.Label>Image de l&apos;ingrédient</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
              />
            </Form.Group>

            <div className="d-flex justify-content-center w-100">
              <Button variant="success" size="sm" className="me-4" type="submit">
                Valider
              </Button>

              <Button variant="primary" size="sm" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

IngredientFormPart.propTypes = {
  selectedEntryColumns: PropTypes.shape({
    title: PropTypes.string,
    quantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    unit: PropTypes.string,
    category: PropTypes.string,
    isAllergen: PropTypes.bool,
    alert: PropTypes.bool,
    increaseMode: PropTypes.bool,
    startingDay: PropTypes.string,
    increaseInterval: PropTypes.number,
    isAdded: PropTypes.bool,
    quantityIncreased: PropTypes.number,
  }).isRequired,
  setSelectedEntryColumns: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};

export default IngredientFormPart;
