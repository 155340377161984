import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import TypingAnimation from '../TypingAnimation';

const BookButton = styled(Link)`
  cursor: pointer;
`;

const Mouse = styled.div`
  cursor: pointer;
`;

function Presentation({ presentationOptions }) {
  return (
    <Container className="pr">
      <Col lg={12} md={12} sm={12} xs={12}>
        <div className="banner-static">
          <div className="banner-text">
            <div className="banner-cell">
              <h1>
                {presentationOptions.title}
                {' '}
                {window.innerWidth <= 480 && <br />}
                <TypingAnimation
                  terms={presentationOptions.terms}
                  animationSpeed={presentationOptions.animationSpeed}
                />
              </h1>
              <h2>{presentationOptions.subtitle}</h2>
              <p>{presentationOptions.description}</p>
              <div className="book-btn">
                <BookButton spy smooth offset={-70} duration={500} to="menu" className="table-btn hvr-underline-from-center">{presentationOptions.buttonText}</BookButton>
              </div>
              <Link spy smooth offset={-70} duration={500} to="about">
                <Mouse className="mouse" />
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </Container>
  );
}

Presentation.propTypes = {
  presentationOptions: PropTypes.shape({
    title: PropTypes.string,
    terms: PropTypes.string,
    animationSpeed: PropTypes.number,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
  }).isRequired,
};

export default Presentation;
