/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import OrderDisplay from '../OrderDisplay';
import { useApi } from '../../../utils/hooks/admin';
import SpinnerWrapper from '../SpinnerWrapper';
import GenericDeleteModal from '../Generic/GenericDeleteModal';

const OrderButton = styled(Button)`
  width: 140px;
`;

const MonthSelect = styled.select`
  width: 140px;
  margin-left: 10px;
`;

function OrdersManagement({ mode }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const mercureUrl = process.env.REACT_APP_MERCURE_URL;

  const { fetchData, authToken } = useApi();

  // Contient toutes les commandes classées
  const [sortedOrders, setSortedOrders] = useState([]);

  // Contient les commandes filtrées par mois
  const [filteredOrders, setFilteredOrders] = useState([]);

  // Contient les temps d'attente (moyen et long)
  const [waitingTime, setWaitingTime] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  // Contient les mois des commandes disponibles
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');

  const [selectedEntries, setSelectedEntries] = useState([]);

  // Trie mes commandes en fonction de leur numéro
  const sortByNumber = () => {
    const sorted = [...sortedOrders].sort((a, b) => a.number - b.number);
    setSortedOrders(sorted);
  };

  // Trie mes commandes en fonction de leur date de création
  const sortByDate = () => {
    const sorted = [...sortedOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    setSortedOrders(sorted);
  };

  // Vérifie l'existence d'une commande et ajoute/supprime cette dernière
  const toggleOrder = (order) => {
    const { id, tableRef } = order;
    const { isOccupied } = tableRef;
    setSortedOrders((prevOrders) => {
      const existingIndex = prevOrders.findIndex((item) => item.id === id);

      // Si la commande existe déjà
      if (existingIndex !== -1) {
        // Si la table est occupée
        if (isOccupied || mode === 'AllCaisse') {
          // Je parcours mes commandes
          return prevOrders.map((item, index) => {
            // jusqu'à trouver celle de la table
            if (index === existingIndex) {
              // Je retourne les informations mises à jour
              return order;
            }
            return item; // Retourne les autres commandes inchangées
          });
        }
        // Si la table n'est pas occupée on retire la commande de l'affichage
        return prevOrders.filter((item) => item.id !== id);
      }
      // Si la commande n'existe pas encore, je l'ajoute
      return [...prevOrders, order];
    });
  };

  const updateDishStatus = (updatedOrderDish) => {
    // Pour éviter les duplications de nom (id), je renomme l'id en orderDishId
    const { id: orderDishId, status, orderRef } = updatedOrderDish;
    const { id } = orderRef;

    setSortedOrders((prevOrders) => {
      // Trouve l'index de la commande dans le tableau prevOrders
      const existingOrderIndex = prevOrders.findIndex((order) => order.id === id);

      // Si la commande existe bien
      if (existingOrderIndex !== -1) {
        const existingOrder = prevOrders[existingOrderIndex];

        // Trouve l'index du plat (orderDish) dans la commande
        const existingOrderDishIndex = existingOrder.orderDishes.findIndex(
          (dish) => dish.id === orderDishId,
        );

        // Si le plat (orderDish) existe bien dans la commande
        if (existingOrderDishIndex !== -1) {
          // Met à jour le status du plat (orderDish) sinon garde le plat tel quel
          const updatedOrderDishes = existingOrder.orderDishes.map(
            (dish, index) => (index === existingOrderDishIndex ? { ...dish, status } : dish),
          );

          // Crée un nouvel objet commande avec les plats (orderDishes) mis à jour
          const updatedOrder = {
            ...existingOrder,
            orderDishes: updatedOrderDishes,
          };

          // Retourne un nouveau tableau de commandes avec la commande mise à jour
          return [
            ...prevOrders.slice(0, existingOrderIndex), // Toutes les commandes avant celle mise à jour
            updatedOrder, // La commande mise à jour
            ...prevOrders.slice(existingOrderIndex + 1), // Toutes les commandes après celle mise à jour
          ];
        }
      }

      // Si la commande ou le plat (orderDish) n'existe pas, retourne le tableau original
      return prevOrders;
    });
  };

  const updateMenuDishStatus = (updatedOrderMenuDish) => {
    // Pour éviter les duplications de nom (id), je renomme l'id en orderMenuDishId et orderMenuId
    const { id: orderMenuDishId, status, orderMenu } = updatedOrderMenuDish;
    const { id: orderMenuId, orderRef: { id: orderId } } = orderMenu;

    setSortedOrders((prevOrders) => {
      // Trouve l'index de la commande dans le tableau prevOrders
      const existingOrderIndex = prevOrders.findIndex((order) => order.id === orderId);

      // Si la commande existe bien
      if (existingOrderIndex !== -1) {
        const existingOrder = prevOrders[existingOrderIndex];

        // Trouve l'index du menu (orderMenu) dans la commande
        const existingOrderMenuIndex = existingOrder.orderMenus.findIndex((menu) => menu.id === orderMenuId);

        // Si le menu (orderMenu) existe bien dans la commande
        if (existingOrderMenuIndex !== -1) {
          const existingOrderMenu = existingOrder.orderMenus[existingOrderMenuIndex];

          // Trouve l'index du plat de menu (orderMenuDish) dans le menu
          const existingOrderMenuDishIndex = existingOrderMenu.orderMenuDishes.findIndex((dish) => dish.id === orderMenuDishId);

          // Si le plat de menu (orderMenuDish) existe bien dans le menu
          if (existingOrderMenuDishIndex !== -1) {
            // Met à jour le status du plat de menu (orderMenuDish) sinon garde le plat tel quel
            const updatedOrderMenuDishes = existingOrderMenu.orderMenuDishes.map(
              (dish, index) => (index === existingOrderMenuDishIndex ? { ...dish, status } : dish),
            );

            // Crée un nouvel objet menu avec les plats de menu (orderMenuDishes) mis à jour
            const updatedOrderMenu = {
              ...existingOrderMenu,
              orderMenuDishes: updatedOrderMenuDishes,
            };

            // Met à jour le menu dans le tableau des menus de la commande
            const updatedOrderMenus = existingOrder.orderMenus.map(
              (menu, index) => (index === existingOrderMenuIndex ? updatedOrderMenu : menu),
            );

            // Crée un nouvel objet commande avec les menus mis à jour
            const updatedOrder = {
              ...existingOrder,
              orderMenus: updatedOrderMenus,
            };

            // Retourne un nouveau tableau de commandes avec la commande mise à jour
            return [
              ...prevOrders.slice(0, existingOrderIndex), // Toutes les commandes avant celle mise à jour
              updatedOrder, // La commande mise à jour
              ...prevOrders.slice(existingOrderIndex + 1), // Toutes les commandes après celle mise à jour
            ];
          }
        }
      }

      // Si la commande, le menu ou le plat de menu n'existe pas, retourne le tableau original
      return prevOrders;
    });
  };

  // Récupère les ids des commandes payées du mois et supprime ces dernières
  const deleteOrders = async () => {
    // Contient toutes les commandes du mois sélectionné
    const idsToDelete = filteredOrders
      // Sauf celles qui ne sont pas encore payées
      .filter((order) => order.isPaid)
      // Contient plus précisément les ids de ces commandes
      .map((order) => order.id);

    // Le state contentant à présent les ids à supprimer déclenche l'appartion de la modale
    setSelectedEntries(idsToDelete);
  };

  // Méthode permettant la fermeture d'une modale
  const handleClose = () => {
    /*
      Après validation de la suppression, on retire de l'affichage les commandes
      dont l'id est dans selectedEntries
    */
    setSortedOrders((prevOrders) => prevOrders.filter(
      (order) => !selectedEntries.includes(order.id),
    ));
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const orderOptions = {
        method: 'GET',
      };
      const restaurantOptions = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      if (mode !== 'AllCaisse') {
        const { data: ordersData } = await fetchData(`${apiUrl}/api/ordersInfos?tableRef.isOccupied=true`, orderOptions);

        setSortedOrders(ordersData['hydra:member']);

        const { data: restaurantData } = await fetchData(`${apiUrl}/api/restaurant_options`, restaurantOptions);

        setWaitingTime(restaurantData['hydra:member'][0]);
      } else {
        const { data: ordersData } = await fetchData(`${apiUrl}/api/ordersInfos`, orderOptions);

        setSortedOrders(ordersData['hydra:member']);
      }

      setIsLoading(false);
    };
    if (authToken) {
      fetchOrders();
    }
  }, [authToken]);

  // On s'abonne au topic des commandes pour les récupérer en temps réel
  useEffect(() => {
    // Url du topic mercure des commandes
    const url = `${mercureUrl}/.well-known/mercure?topic=/api/orders`;
    const eventSource = new EventSource(url);

    // Quand une commande est mise à jour
    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      if (eventData.id) {
        toggleOrder(eventData);
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  // Extraire les mois des commandes
  useEffect(() => {
    // Contiendra tous les mois des commandes (Set pour éviter les doublons)
    const monthsSet = new Set();

    // Je parcours mes commandes
    sortedOrders.forEach((order) => {
      // J'en déduis tous les mois des commandes
      const month = new Date(order.orderDate).toLocaleString('default', { month: 'long', year: 'numeric' });

      // Je les ajoute à mon Set
      monthsSet.add(month);
    });

    // Je transforme mon Set en array et je le mets dans mon state
    setMonths(Array.from(monthsSet));
  }, [sortedOrders]);

  // Filtrer les commandes par mois
  useEffect(() => {
    // Si pas de filtre
    if (selectedMonth === '') {
      // On retourne le tableau dans son état originel
      setFilteredOrders(sortedOrders);
    } else {
      // Sinon on filtre ce dernier en ne conservant que ceux dont le mois est celui sélectionné
      const filtered = sortedOrders.filter((order) => {
        const month = new Date(order.orderDate).toLocaleString('default', { month: 'long', year: 'numeric' });
        return month === selectedMonth;
      });
      setFilteredOrders(filtered);
    }
  }, [selectedMonth, sortedOrders]);

  // Se déclenche quand on change le mois de filtre
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // On s'abonne au topic du status des plats pour le récupérer en temps réel
  useEffect(() => {
    // Url du topic mercure du status des plats
    const url = `${mercureUrl}/.well-known/mercure?topic=/api/order_dishes`;
    const eventSource = new EventSource(url);

    // Quand une commande est mise à jour
    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      // Si mercure ne m'envoie pas d'id c'est une suppression donc pas de traitement
      if (eventData.id) {
        updateDishStatus(eventData);
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  // On s'abonne au topic du status des plats de menus pour les récupérer en temps réel
  useEffect(() => {
    // Url du topic mercure du status des plats de menus
    const url = `${mercureUrl}/.well-known/mercure?topic=/api/order_menu_dishes`;
    const eventSource = new EventSource(url);

    // Quand une commande est mise à jour
    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      // Si mercure ne m'envoie pas d'id c'est une suppression donc pas de traitement
      if (eventData.id) {
        updateMenuDishStatus(eventData);
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <div>
        <div className="container-fluid pt-4 px-4">
          <div className="d-flex justify-content-center mb-4">
            {mode !== 'AllCaisse' && <OrderButton type="button" className="btn btn-primary me-4 btn-info" onClick={sortByNumber}>Trier par table</OrderButton>}
            {mode !== 'AllCaisse' && <OrderButton type="button" className="btn btn-primary ms-4 btn-info" onClick={sortByDate}>Trier par heure</OrderButton>}
            {mode === 'AllCaisse' && (
              <>
                <MonthSelect value={selectedMonth} onChange={handleMonthChange}>
                  <option value="">Tous les mois</option>
                  {months.map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </MonthSelect>
                <OrderButton type="button" className="btn btn-danger ms-4 btn-info" onClick={deleteOrders}>Supprimer</OrderButton>
              </>
            )}
          </div>
          <div className="row">
            {filteredOrders.map((order) => (
              <div key={order.id} className="col-lg-4 col-md-6 col-sm-12 pb-4">
                <OrderDisplay order={order} mode={mode} waitingTime={waitingTime} />
              </div>
            ))}
          </div>
        </div>
        {selectedEntries.length > 0 && (
          <GenericDeleteModal
            name="orders"
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            handleClose={handleClose}
            handleSuccess={() => {
              setSelectedEntries([]);
            }}
          />
        )}
      </div>
    </>
  );
}

OrdersManagement.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default OrdersManagement;
