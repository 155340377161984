/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const MenuContainer = styled.div`
  display: flex;
  // Règle le soucis de taille des éléments de carousel
  flex: 0 0 100%;
  flex-direction: row;
  background-color: #202020;
  z-index: 9999;
  padding: 30px;
  color: white !important;
  font-size: 18px;
  vertical-align: auto !important;
  transition: transform 2s ease;
  transform: translateX(${(props) => props.$carouselIndex * -100}%);
  overflow-y: scroll;
  max-height: 90vh;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

  p {
    margin: 0px;
  }

  label {
    font-weight: initial;
  }
`;

const MenuImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const MenuImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;

const MenuInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 20px 0px 20px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
  }
`;

const MenuTitle = styled.h1`
  text-align: center;
  color: white;
  font-family: 'nautilus_pompiliusregular';
  font-size: 52px;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
`;

const MenuSubInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
`;
const MenuDescription = styled.div`
  padding: 10px 0px 10px 0px;
`;

const MenuComposition = styled.div`
  padding: 10px 0px 10px 0px;
`;

const MenuValidation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
`;

const MenuAdd = styled(Button)`
  white-space: normal;
  width: 150px;

  @media screen and (max-width: 480px) {
    width: 100px;
  }
`;

const MenuAbord = styled(Button)`
  width: 150px;

  @media screen and (max-width: 480px) {
    width: 100px;
  }
`;

function MenuPresentation({
  menu,
  uniqueCategories,
  carouselIndex,
  setCarouselIndex,
  setDishDetails,
  tableNumber,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [compositionTexts, setCompositionTexts] = useState([]);

  const handleAbord = () => {
    // Ferme la modale de description du produit
    setDishDetails((prevDetails) => ({ ...prevDetails, display: false }));
  };

  useEffect(() => {
    // Permet de créer le texte d'instruction quant à la sélection des plats du menu
    const buildTextByCategory = (category) => {
      // On récupère les menuDish d'une catégorie donnée
      const filteredDishes = menu.menuDishes.filter(
        (menuDish) => menuDish.dish.category === category,
      );

      // On récupère le title de ces menuDish
      const dishTitles = filteredDishes.map((filteredDish) => filteredDish.dish.title);

      // On crée le descriptif final pour les éléments de cette catégorie
      return `1 ${category} parmi ${dishTitles.join(', ')}`;
    };

    const textsByCategory = uniqueCategories.map((category) => buildTextByCategory(category));

    setCompositionTexts(textsByCategory);
  }, []);

  return (
    <MenuContainer $carouselIndex={carouselIndex}>
      <MenuImageContainer>
        <MenuImage src={`${apiUrl}/images/menus/${menu.imageName}`} />
      </MenuImageContainer>
      <MenuInfos>
        <MenuTitle>{menu.title}</MenuTitle>
        <MenuSubInfos>
          <p>
            <span style={{ textDecoration: 'underline' }}>Catégorie</span>
            {' '}
            :
            {' '}
            Menu
          </p>
          <p>
            <span style={{ textDecoration: 'underline' }}>Prix</span>
            {' '}
            :
            {' '}
            {menu.price}
            {' '}
            €
          </p>
        </MenuSubInfos>
        <MenuDescription>{menu.description}</MenuDescription>
        <MenuComposition>
          <p>
            <span style={{ textDecoration: 'underline' }}>Composition</span>
            {' '}
            :
          </p>
          <ul>
            {compositionTexts.map((compositionText) => (
              <li style={{ marginBottom: '10px' }} key={compositionText}>
                -
                {' '}
                {compositionText}
              </li>
            ))}
          </ul>
        </MenuComposition>
        <MenuValidation>
          <MenuAdd variant="success" onClick={() => setCarouselIndex((prevCarouselIndex) => prevCarouselIndex + 1)}>
            {tableNumber !== -1 ? 'Choisir mes plats' : 'Voir les plats'}
          </MenuAdd>
          <MenuAbord variant="danger" onClick={handleAbord}>
            Fermer
          </MenuAbord>
        </MenuValidation>
      </MenuInfos>
    </MenuContainer>
  );
}

MenuPresentation.propTypes = {
  menu: PropTypes.shape({
    imageName: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
    menuDishes: PropTypes.arrayOf(
      PropTypes.shape({

      }),
    ),
  }).isRequired,
  uniqueCategories: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  carouselIndex: PropTypes.number.isRequired,
  setCarouselIndex: PropTypes.func.isRequired,
  setDishDetails: PropTypes.func.isRequired,
  tableNumber: PropTypes.number.isRequired,
};

export default MenuPresentation;
