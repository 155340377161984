/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Container, Dropdown, Form, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { useApi } from '../../../../utils/hooks/admin';
import {
  getLabelForColumn,
  getColumnValue,
  getTextColorClass,
  getCacheContent,
  getUpdatedCacheContent,
  getSortParam,
  titles,
} from '../../../../utils/helpers/Generic/GenericTable';
import SpinnerWrapper from '../../SpinnerWrapper';
import GenericDeleteModal from '../GenericDeleteModal';
import GenericAddAndEditModal from '../GenericAddAndEditModal';
import GenericAssociatedAddAndEditModal from '../GenericAssociatedAddAndEditModal';
import Searcher from '../../Searcher';
import QRCode from '../../QRCode';

function GenericTable({
  name, // Nom de l'entité associée au tableau
  displayedColumns, // Objet contenant l'information sur les colonnes à afficher ou non
  setDisplayedColumns, // Met à jour les colonnes à afficher
  associatedName, // Nom de l'entité en association avec celle affichée dans le tableau
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const mercureUrl = process.env.REACT_APP_MERCURE_URL;

  const {
    setErrors, fetchData, authToken, authUser, authPermissions, cache, updateCache,
  } = useApi();

  // State contenant le tableau de tous les objets entité à afficher
  const [entries, setEntries] = useState([]);

  // State contenant une partie issue d'un filtre de l'ensemble du tableau d'objet
  const [filteredEntries, setFilteredEntries] = useState([]);

  // State contenant un objet (une entrée) en particulier
  const [selectedEntry, setSelectedEntry] = useState(null);

  // Sate contenant un tableau des objets dont la checkbox a été cochée, destinés à être supprimés
  const [selectedEntries, setSelectedEntries] = useState([]);

  // State permettant de gérer l'ouverture et la fermeture d'une modale
  const [modalOpen, setModalOpen] = useState(false);

  // State permettant de définir la modale à utiliser (édition, suppression etc...)
  const [modalType, setModalType] = useState(null);

  // State permettant de "recharger" la page en redéclenchant la requête GET
  const [reloadData, setReloadData] = useState(false);

  // State permettant de gérer l'état de la liste déroulante du choix des colonnes à afficher
  const [showDropdown, setShowDropdown] = useState(false);

  // State contenant l'ordre de classement des différentes colonnes classables
  const [sortOrders, setSortOrders] = useState({
    realName: 'asc',
    title: 'asc',
    hireDate: 'asc',
    endDate: 'asc',
    quantity: 'asc',
    percentQuantity: 'asc',
    category: 'asc',
    price: 'asc',
    isSaled: 'asc',
    number: 'asc',
    isOccupied: 'asc',
  });

  const [isLoading, setIsLoading] = useState(true);

  // State contenant le terme actuellement entré pour la recherche d'une entrée particulière
  const [searchTerm, setSearchTerm] = useState('');

  // State contenant le qrCode d'une table
  const [qrCode, setQrCode] = useState(null);

  // Contient les options générales
  const [restaurantOption, setRestaurantOption] = useState({});

  // Méthode déclenchée au clic sur la liste déroulante et inversant son état (ouvert / fermé)
  const handleToggle = () => setShowDropdown(!showDropdown);

  // Méthode permettant d'ajouter ou de retirer une entrée dans le tableau destiné à la suppression
  const handleCheckboxChange = (entry) => {
    setSelectedEntries((prevEntries) => {
      // Si l'entrée était déjà présente on la retire sinon on l'ajoute
      const updatedEntries = prevEntries.includes(entry)
        ? prevEntries.filter((identifier) => identifier !== entry)
        : [...prevEntries, entry];

      return updatedEntries;
    });
  };

  // Méthode qui inverse l'état d'une colonne à afficher (affiche / camoufle)
  const handleColumnChange = (columnName) => {
    setDisplayedColumns(
      (prevColumns) => ({ ...prevColumns, [columnName]: !prevColumns[columnName] }),
    );
  };

  // Méthode qui déclenche l'affichage de la modale d'édition
  const handleEditClick = (entry) => {
    // Met le state à jour avec l'entrée dont le bouton d'édition a été cliqué
    setSelectedEntry(entry);

    // Met à jour le state permettant de déterminer le type de modale à ouvrir
    setModalType('edit');

    // Permet l'ouverture de la modale
    setModalOpen(true);
  };

  // Méthode qui déclenche l'affichage de la modale d'ajout
  const handleAddClick = () => {
    // Met à jour le state permettant de déterminer le type de modale à ouvrir
    setModalType('add');

    // Permet l'ouverture de la modale
    setModalOpen(true);
  };

  // Méthode qui déclenche l'affichage de la modale de suppression
  const handleDeleteClick = () => {
    // Met à jour le state permettant de déterminer le type de modale à ouvrir
    setModalType('delete');

    // Permet l'ouverture de la modale
    setModalOpen(true);
  };

  // Méthode permettant la fermeture d'une modale
  const handleModalClose = () => {
    // Permet la fermeture de la modale
    setModalOpen(false);

    // Plus aucune entrée ne doit être considérée comme sélectionnée
    setSelectedEntry(null);

    // Plus aucun type de modale à gérer ne doit être défini
    setModalType(null);

    // On met à jour le state contenant les erreurs qu'auraient pu être affichée dans la modale
    setErrors([]);
  };

  // Méthode permettant de classer par ordre asc ou desc mes entrées en fonction d'une colonne
  const toggleSortOrder = (columnValue) => {
    let cachedData;

    if (name !== 'ingredients') {
      // Contient le contenu actuel du cache pour la ressource associée au tableau
      cachedData = getCacheContent(name, cache);

    // La ressource ingredient n'existe pas en cache, je travaille donc directement sur entries
    } else {
      // Attention, il faut une copie d'entries et ne pas le référencer directement
      cachedData = [...entries];
    }

    // Classe le cache dans un ordre asc ou desc en comparant les éléments
    const sortedData = cachedData.sort((a, b) => {
      let aValue;
      let bValue;

      // Si les valeurs sont des dates
      if (name === 'users' && (columnValue === 'hireDate' || columnValue === 'endDate')) {
        // Convertir les chaînes de date en objets Date
        aValue = new Date(a[columnValue]);
        bValue = new Date(b[columnValue]);

        // Si les dates sont non définies
        if (Number.isNaN(aValue.getTime())) {
          aValue = new Date('9999-12-31'); // Placer les dates non définies à la fin
        }
        if (Number.isNaN(bValue.getTime())) {
          bValue = new Date('9999-12-31'); // Placer les dates non définies à la fin
        }
        // Si les valeurs sont des pourcentages
      } else if (columnValue === 'percentQuantity') {
        aValue = (a.quantity / a.maxQuantity) * 100;
        bValue = (b.quantity / b.maxQuantity) * 100;
      } else {
        // Autrement, utiliser directement la valeur
        aValue = Object.prototype.hasOwnProperty.call(a, columnValue) ? a[columnValue] : '';
        bValue = Object.prototype.hasOwnProperty.call(b, columnValue) ? b[columnValue] : '';
      }

      // Si les valeurs sont des nombres ou des dates
      if ((typeof aValue === 'number' || aValue instanceof Date)
      && (typeof bValue === 'number' || bValue instanceof Date)) {
        // Si on doit classer par asc
        if (['asc', ''].includes(sortOrders[columnValue])) {
          return aValue - bValue;
        }
        // Si on doit classer par desc
        return bValue - aValue;
      }
      // Sinon, trier les valeurs en utilisant localeCompare pour les chaînes de caractères
      if (['desc', ''].includes(sortOrders[columnValue])) {
        // Si on doit classer par desc
        return aValue.toString().localeCompare(bValue.toString());
      }
      // Si on doit classer par asc
      return bValue.toString().localeCompare(aValue.toString());
    });

    // Je n'utilise pas le cache pour ingredient
    if (name !== 'ingredients') {
      // Contient les informations nécessaires pour la mise à jour du cache trié
      const updatedCacheData = getUpdatedCacheContent(name, cache, sortedData);

      // Mise à jour du cache pour la ressource après tri
      updateCache(updatedCacheData.apiUrl, updatedCacheData.updatedCacheData);
    }

    // Inverse le mode de tri pour que le prochain se fasse dans l'autre sens
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [columnValue]: prevSortOrders[columnValue] === 'asc'
      || prevSortOrders[columnValue] === '' ? 'desc' : 'asc',
    }));

    setEntries(sortedData);
  };

  // Permet au serveur de commander pour le client
  const handleTakingOrder = (urlIdentifier) => {
    window.location.href = `/restaurant/${urlIdentifier}`;
  };

  // Permet de requêter le serveur pour récupérer le QR Code de l'url associée
  const handleShowQRCode = async (url) => {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `url=${encodeURIComponent(url)}`,
    };

    // Utilisez directement la réponse de fetch ici
    const response = await fetch(`${apiUrl}/api/qrcode`, options);

    // Vérifiez que la réponse est OK et que le contenu est de type image
    if (response.ok && response.headers.get('Content-Type').includes('image')) {
      // Attendez que la promesse blob soit résolue
      const qrCodeBlob = await response.blob();

      // Créez une URL pour le Blob et mettez à jour l'état
      const qrCodeUrl = URL.createObjectURL(qrCodeBlob);
      setQrCode(qrCodeUrl);
    }
  };

  // Fonction pour mettre à jour les infos si l'ingrédient est trouvé
  const updateIngredientInfos = (eventData) => {
    // Pour chaque ingrédient
    setEntries((prevEntries) => prevEntries.map((entry) => {
      // Si l'ingrédient dont les infos sont modifiées existe
      if (entry.id === eventData.id) {
        // Je remplace ses informations
        return eventData;
      }
      // Sinon je le garde tel quel
      return entry;
    }));
  };

  /*
    Se déclenche au montage initial et quand les opérations liées aux modales ont réussi.
    Requête l'API afin de récupérer la liste des entités
  */
  useEffect(() => {
    // Méthode permettant la récupération de la liste des entités
    const fechDataAsync = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };

      let apiPath = `${apiUrl}/api/${name}`;

      // On affiche que les plats et menus non soft delete
      if (name === 'dishes' || name === 'menus') {
        apiPath += '?isDeleted=false';
      }

      // Requête l'API récupérant la liste des entités et construisant le cache de ces derniers
      const { data } = await fetchData(apiPath, options);

      // Si aucune donnée n'est retournée
      if (!data) {
        // On retire le loading
        setIsLoading(false);

        // On ne fait rien de plus et on sort de la méthode
        return;
      }

      // Je récupère toutes les informations pertinentes concernant mes entrées
      setEntries(data['hydra:member'].map((entry) => ({
        ...entry,
      })));

      // Les données nécessaires à l'affichage ont été récupérées. Je retire le loading
      setIsLoading(false);
    };

    fechDataAsync();
  }, [reloadData]);

  // Après récupération de la liste de mes entités je copie cette dernière dans le state filtre
  useEffect(() => {
    if (entries.length >= 0) {
      setFilteredEntries(entries);
    }
  }, [entries]);

  useEffect(() => {
    // On s'abonne au topic des ingredients pour récupérer la nouvelle quantité
    const getNewIngredientQuantity = () => {
      // Url du topic mercure des ingredients
      const url = `${mercureUrl}/.well-known/mercure?topic=/api/ingredients`;
      const eventSource = new EventSource(url);

      // Quand un ingrédient est mise à jour
      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        updateIngredientInfos(eventData);
      };

      eventSource.onerror = () => {
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    };

    // Si tableau des ingrédients on récupère les options pour trouver les seuils d'alerte
    const fetchRestaurantOptions = async () => {
      const options = {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      };
      // Requête l'API récupérant les options gérénales
      const { data } = await fetchData(`${apiUrl}/api/restaurant_options`, options);

      setRestaurantOption(data['hydra:member'][0]);
    };

    if (name === 'ingredients') {
      getNewIngredientQuantity();
      fetchRestaurantOptions();
    }
  }, []);

  return (
    <>
      <SpinnerWrapper $showSpinner={isLoading} />
      <Container fluid className="pt-4 px-4">
        <div className="bg-secondary text-center rounded p-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="mb-0">{titles[name]}</h6>
          </div>

          {name !== 'tables' && (
            <Searcher
              name={name}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allEntities={entries}
              setPartEntities={setFilteredEntries}
            />
          )}

          <Dropdown show={showDropdown} className="mb-3 d-flex justify-content-center">
            <Dropdown.Toggle variant="info" size="sm" id="dropdown-basic" onClick={handleToggle}>
              Informations à afficher
            </Dropdown.Toggle>

            <Dropdown.Menu className="ps-3 pe-3" style={{ width: '167px', fontSize: '.875rem' }}>
              {Object.entries(displayedColumns).map(([columnName, isActive]) => (
                <Form.Check
                  key={columnName}
                  type="checkbox"
                  label={getLabelForColumn(columnName)}
                  checked={isActive}
                  onChange={() => handleColumnChange(columnName)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className="table-responsive">
            <Table className="text-start align-middle table-bordered table-hover mb-0">
              <thead>
                <tr className="text-white">
                  {((authPermissions.includes('Suppression des employés')
                  && name === 'users')
                  || (authPermissions.includes('Suppression des ingrédients')
                  && name === 'ingredients')
                  || (authPermissions.includes('Suppression des plats')
                  && name === 'dishes')
                  || (authPermissions.includes('Suppression des menus')
                  && name === 'menus')
                  || (authPermissions.includes('Suppression de tables')
                  && name === 'tables'))
                  && <th scope="col" />}
                  {((name === 'users'
                  || name === 'ingredients'
                  || name === 'dishes'
                  || name === 'menus')
                  && (
                  <th
                    scope="col"
                    className="text-center sortable"
                    onClick={() => toggleSortOrder(getSortParam(name))}
                  >
                    <div className="d-inline-block  me-2 align-middle">
                      Nom
                    </div>
                    <div className="d-inline-block">
                      <FontAwesomeIcon icon={faSortDesc} />
                    </div>
                  </th>
                  )
                  )}

                  {Object.entries(displayedColumns).map(([columnValue, isActive]) => isActive && (
                  <th
                    key={columnValue}
                    scope="col"
                    className={
                      `text-center
                      ${Object.keys(sortOrders).includes(columnValue)
                        ? 'sortable' : ''
                      }`
                    }
                    onClick={
                      Object.keys(sortOrders).includes(columnValue)
                        ? () => toggleSortOrder(columnValue) : null
                    }
                  >
                    <div className="d-inline-block me-2 align-middle">
                      {getLabelForColumn(columnValue)}
                    </div>

                    {Object.keys(sortOrders).includes(columnValue)
                    && (
                    <div className="d-inline-block">
                      <FontAwesomeIcon icon={faSortDesc} />
                    </div>
                    )}
                  </th>
                  ))}
                  {((authPermissions.includes('Mise à jour des informations des employés')
                  && name === 'users')
                  || (authPermissions.includes('Mise à jour des ingrédients')
                  && name === 'ingredients')
                  || (authPermissions.includes('Mise à jour des tables')
                  && name === 'tables')
                  || (name === 'dishes')
                  || (name === 'menus'))
                  && <th scope="col" />}
                  {name === 'tables' && (
                    <>
                      <th scope="col" />
                      <th scope="col" />
                    </>
                  )}
                </tr>
              </thead>

              <tbody>

                {/*
                  La méthode map itère sur chaque élément du tableau 'entries'
                  et applique une fonction à chaque 'user'
                */}
                {filteredEntries.map((entry) => {
                  const textColorClass = name === 'ingredients'
                    ? getTextColorClass(
                      (entry.quantity / entry.maxQuantity) * 100,
                      restaurantOption.stockWarningThreshold,
                      restaurantOption.stockCriticalThreshold,
                    )
                    : '';

                  // Déterminer la classe basée sur 'isOccupied'
                  let occupiedClass = '';
                  if (entry.isOccupied !== undefined) {
                    occupiedClass = entry.isOccupied ? 'text-success' : 'text-danger';
                  }

                  // Combiner toutes les classes
                  const combinedClassName = `text-center ${textColorClass} ${occupiedClass}`;

                  return (
                    <tr key={entry.id}>
                      {(() => {
                        if (authPermissions.includes('Suppression des employés')
                        && name === 'users') {
                          // Vérifie si l'utilisateur de la boucle n'est pas l'utilisateur connecté
                          const isCurrentUser = authUser === entry.username;

                          // Si ce n'est pas l'utilisateur courant, affichez la case à cocher
                          return !isCurrentUser ? (
                            <td className="text-center">
                              <Form.Check
                                type="checkbox"
                                onChange={() => handleCheckboxChange(entry.username)}
                                checked={selectedEntries.includes(entry.username)}
                              />
                            </td>
                          ) : (
                          // Sinon, affichez une cellule vide
                            <td />
                          );
                        }
                        // Si l'utilisateur n'a pas la permission ou si le nom n'est pas 'users'
                        return null;
                      })()}
                      {((authPermissions.includes('Suppression des ingrédients')
                      && name === 'ingredients')
                      || (authPermissions.includes('Suppression des plats')
                      && name === 'dishes')
                      || (authPermissions.includes('Suppression des menus')
                      && name === 'menus'))
                      && (
                      <td className="text-center">
                        <Form.Check
                          type="checkbox"
                          onChange={() => handleCheckboxChange(entry.title)}
                          checked={selectedEntries.includes(entry.title)}
                        />
                      </td>
                      )}
                      {((authPermissions.includes('Suppression de tables')
                      && name === 'tables'))
                      && (
                      <td className="text-center">
                        <Form.Check
                          type="checkbox"
                          onChange={() => handleCheckboxChange(entry.number)}
                          checked={selectedEntries.includes(entry.number)}
                        />
                      </td>
                      )}
                      {name !== 'tables' && (
                        <td className={
                          `text-center
                          ${name === 'ingredients'
                            ? getTextColorClass(
                              (entry.quantity / entry.maxQuantity) * 100,
                              restaurantOption.stockWarningThreshold,
                              restaurantOption.stockCriticalThreshold,
                            ) : ''
                          }`
                        }
                        >
                          {(() => {
                            if (name === 'users') {
                              return entry.realName;
                            } if (name === 'ingredients' || name === 'dishes' || name === 'menus') {
                              return entry.title;
                            }
                            return '';
                          })()}
                        </td>
                      )}
                      {Object.entries(displayedColumns).map(([columnKey, isActive]) => (
                        isActive && (
                          <td
                            key={columnKey}
                            className={combinedClassName}
                          >
                            {getColumnValue(entry, columnKey)}
                          </td>
                        )
                      ))}

                      {name === 'tables' && (
                        <td className="text-center">
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleTakingOrder(entry.urlIdentifier)}
                          >
                            Commander
                          </Button>
                        </td>
                      )}

                      {((authPermissions.includes('Mise à jour des informations des employés')
                      && name === 'users')
                      || (authPermissions.includes('Mise à jour des ingrédients')
                      && name === 'ingredients')
                      || (authPermissions.includes('Mise à jour des plats')
                      && name === 'dishes')
                      || (authPermissions.includes('Mise à jour des menus')
                      && name === 'menus')
                      || (authPermissions.includes('Mise à jour des tables')
                      && name === 'tables'))
                        ? (
                          <td className="text-center">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleEditClick(entry)}
                            >
                              Modifier
                              {(name === 'dishes' || name === 'menus') && (
                                ' / Infos'
                              )}
                            </Button>
                          </td>
                        ) : (
                          ((name === 'dishes' || name === 'menus') && (
                            <td className="text-center">
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() => handleEditClick(entry)}
                              >
                                Plus d&apos;infos
                              </Button>
                            </td>
                          ))
                        )}
                      {name === 'tables' && (
                        <td>
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() => handleShowQRCode(`https://ti-pei-gourmand.fr/restaurant/${entry.urlIdentifier}`)}
                          >
                            QR Code
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-center mt-4">
            {((authPermissions.includes('Inscription de nouveaux employés')
            && name === 'users')
            || (authPermissions.includes('Inscription de nouveaux ingrédients')
            && name === 'ingredients')
            || (authPermissions.includes('Inscription de nouveaux plats')
            && name === 'dishes')
            || (authPermissions.includes('Inscription de nouveaux menus')
            && name === 'menus')
            || (authPermissions.includes('Inscription de nouvelles tables')
            && name === 'tables'))
            && (
            <Button
              variant="success"
              size="sm"
              className="me-4"
              onClick={() => handleAddClick()}
            >
              Ajouter
            </Button>
            )}

            {((authPermissions.includes('Suppression des employés')
            && name === 'users')
            || (authPermissions.includes('Suppression des ingrédients')
            && name === 'ingredients')
            || (authPermissions.includes('Suppression des plats')
            && name === 'dishes')
            || (authPermissions.includes('Suppression des menus')
            && name === 'menus')
            || (authPermissions.includes('Suppression de tables')
            && name === 'tables'))
            && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleDeleteClick()}
              disabled={selectedEntries.length === 0}
            >
              Supprimer
            </Button>
            )}
          </div>

        </div>

        {modalOpen && (modalType === 'add' || modalType === 'edit') && (
          ((name === 'users' || name === 'dishes' || name === 'menus') && (
          <GenericAssociatedAddAndEditModal
            name={name}
            associatedName={associatedName}
            handleClose={handleModalClose}
            handleSuccess={() => {
              setReloadData(!reloadData);
            }}
            selectedEntry={selectedEntry}
          />
          ))
          || ((name === 'ingredients' || name === 'tables') && (
          <GenericAddAndEditModal
            name={name}
            handleClose={handleModalClose}
            handleSuccess={() => {
              setReloadData(!reloadData);
            }}
            selectedEntry={selectedEntry}
          />
          )))}

        {modalOpen && modalType === 'delete' && (
          <GenericDeleteModal
            name={name}
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            handleClose={handleModalClose}
            handleSuccess={() => {
              setReloadData(!reloadData);
            }}
          />
        )}
        {qrCode && (
          <QRCode qrCode={qrCode} setQrCode={setQrCode} />
        )}
      </Container>
    </>
  );
}

GenericTable.propTypes = {
  name: PropTypes.string.isRequired,
  associatedName: PropTypes.string,
  displayedColumns: PropTypes.oneOfType([
    PropTypes.shape({
      username: PropTypes.bool,
      roles: PropTypes.bool,
      phoneNumber: PropTypes.bool,
      email: PropTypes.bool,
      hireDate: PropTypes.bool,
      endDate: PropTypes.bool,
      employmentStatus: PropTypes.bool,
      socialSecurityNumber: PropTypes.bool,
      comments: PropTypes.bool,
    }),
    PropTypes.shape({
      title: PropTypes.bool,
      quantity: PropTypes.bool,
      maxQuantity: PropTypes.bool,
      unit: PropTypes.bool,
      category: PropTypes.bool,
      isAllergen: PropTypes.bool,
    }),
    PropTypes.shape({
      description: PropTypes.bool,
      price: PropTypes.bool,
      category: PropTypes.bool,
      ingredient: PropTypes.bool,
      isAvailable: PropTypes.bool,
      isAllergen: PropTypes.bool,
    }),
  ]).isRequired,
  setDisplayedColumns: PropTypes.func.isRequired,
};

GenericTable.defaultProps = {
  associatedName: null,
};

export default GenericTable;
